import { template as template_7a4e7864f79f40e2a52bb7aeeb469f53 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7a4e7864f79f40e2a52bb7aeeb469f53(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
