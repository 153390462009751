import { template as template_a3a8b173298c4c318d663d827e6f7475 } from "@ember/template-compiler";
const FKLabel = template_a3a8b173298c4c318d663d827e6f7475(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
