import { template as template_739c43dd6a9949369782815f6e24beee } from "@ember/template-compiler";
const FKText = template_739c43dd6a9949369782815f6e24beee(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
