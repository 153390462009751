import { template as template_fadf27d09da844f182eb6216e3472f41 } from "@ember/template-compiler";
const FKControlMenuContainer = template_fadf27d09da844f182eb6216e3472f41(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
